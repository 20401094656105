enum Role {
    NotYetChosen,
    Servant,
    Merlin,
    Percival,
    Minion,
    Morgana,
    Mordred,
    Assassin,
    Oberon
}

export default Role;